<template>
  <v-container id="plans" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="plans"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-dialog v-model="dialogItem" persistent max-width="750px">
            <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="newTraininPlan()">
              {{ str['add'] }}
            </v-btn>

            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['plan'] }}
                  {{ itemToEdit && itemToEdit.date ? '(' + itemToEdit.date + ')' : '' }}
                </span>
              </v-card-title>

              <v-col cols="12">
                <v-text-field
                  v-model="itemName"
                  :style="{
                    'pointer-events': editPermission ? '' : 'none',
                  }"
                  name="input-7-1"
                  :label="str['name']"
                  class="purple-input"
                  style="margin-top: 10px"
                  hide-details
                />
              </v-col>

              <div
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
              >
                <div v-if="editPermission" style="padding: 20px; display: flex; justify-content: center; align-items: center">
                  <v-btn color="blue" class="mr-0">
                    {{ str['add_pdf'] }}
                  </v-btn>
                  <input
                    id="input-pdf-value"
                    type="file"
                    accept="application/pdf,application/vnd.ms-excel"
                    style="opacity: 0; position: absolute; height: 45px; width: 160px"
                    @change="loadPdf"
                  />
                </div>
                <div style="padding: 0 0 20px">
                  <iframe v-if="pdfValue" :src="pdfValue" style="width: 100%; height: 50vh" />
                </div>
              </div>

              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="closePlan()">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn v-if="editPermission" color="success" @click="save()">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" color="teal lighten-2" @click.stop="duplicateItem(item)">mdi-content-duplicate</v-icon>
        <v-icon small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('base_trng_plans_edit')
    const headers = [
      { text: window.strings['id'], value: 'id' },
      { text: window.strings['name'], value: 'name', align: 'center' },
    ]

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    return {
      str: window.strings,
      user: user,
      plans: [],
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('plansBase', 'itemsPerPage') || 10,
      search: '',
      editPermission: editPermission,
      headers: headers,
      itemName: '',
      pdfValue: '',
      dialogItem: false,
      itemToEdit: null,
      planType: 1,
    }
  },
  watch: {
    dialogItem(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closePlan()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getPlans(true)
  },
  beforeDestroy: function () {
    Utils.setTableCache('plansBase', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('plansBase', 'page', this.currentPage)
  },
  methods: {
    getPlans: function (getCachePage) {
      const self = this
      Api.getTrainingPlans(
        {
          fields: ['id', 'name', 'type', 'gender', 'place', 'level'],
          fast: true,
          type: this.planType,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.plans = response.data
            if (getCachePage) {
              self.currentPage = Utils.getTableCache('plansBase', 'page') || self.currentPage
            }
          } else {
            self.plans = []
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openPlan: function (item) {
      const self = this
      this.getPlan(item, function (src) {
        self.$isLoading(false)
        self.setData(src)
        self.itemToEdit = src
        self.dialogItem = true
        self.resetPdfInput()
      })
    },
    newTraininPlan: function () {
      this.setData()
      this.itemToEdit = null
      this.dialogItem = true
      this.resetPdfInput()
    },
    closePlan() {
      this.itemToEdit = null
      this.dialogItem = false
    },
    resetPdfInput: function () {
      setTimeout(function () {
        if (document.getElementById('input-pdf-value')) {
          document.getElementById('input-pdf-value').value = ''
        }
      })
    },
    loadPdf(e) {
      const self = this
      if (!e.target.files.length) {
        return false
      }
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        self.pdfValue = e.target.result
      }
    },
    setData: function (data) {
      if (data) {
        this.itemName = data.name
        this.pdfValue = data.details
      } else {
        this.itemName = ''
        this.pdfValue = ''
      }
    },
    save: function () {
      const self = this
      const data = this.getData()
      this.$isLoading(true)

      if (data.id) {
        Api.editTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogItem = false
            self.getPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        delete data.id
        Api.newTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogItem = false
            self.getPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    getData: function () {
      const data = {
        name: this.itemName,
        details: this.pdfValue,
        type: this.planType,
        base: 1,
      }
      if (this.itemToEdit && this.itemToEdit.id) {
        data.id = this.itemToEdit.id
      }
      return data
    },
    deleteItem(item) {
      const self = this
      const id = item.id
      const data = {
        id: id,
        client_id: null,
      }

      this.$confirm(
        window.strings['want_delete_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.plans.length; i++) {
                if (self.plans[i].id === id) {
                  self.plans.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    duplicateItem(item) {
      const self = this

      this.$confirm(
        window.strings['want_duplicate_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'info',
        Utils.getAlertOptions(true, false),
      )
        .then(() => {
          self.getPlan(item, function (src) {
            Api.newTrainingPlan(src, function (response) {
              if (response.success) {
                self.getPlans()
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          })
        })
        .catch(() => {})
    },
    getPlan(plan, callback) {
      const self = this
      const payload = {
        id: plan.id,
      }
      this.$isLoading(true)
      Api.getTrainingPlan(payload, function (response) {
        if (response.success) {
          callback(response.data[0])
        } else {
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
  },
}
</script>
